<template>
  <b-form
    @submit.prevent="$emit('processCuenta', usuario)"
    class="mt-3"
  >
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Imagen -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >

          <b-form-group
            label-for="imagen"
          >
          <!-- {{usuario.imagen}} -->
            <!-- Media -->
            <b-media class="mb-2">
              <template #aside>
                <b-avatar
                  ref="previewEl"
                  :src="usuario.imagen"
                  :text="usuario.nombre == '' ? 'P' : avatarText(usuario.nombre)"
                  size="90px"
                  rounded
                  variant="light-success"
                  :title="usuario.nombre == '' ? 'P' : avatarText(usuario.nombre)"
                />
              </template>

              <div class="mb-1">
                <h3 class="mb-0">
                  {{ usuario.nombre }}
                </h3>
                <span class="card-text">{{ usuario.correo }}</span>
              </div>

            </b-media>
          </b-form-group>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">

            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-50"
                />
                <span class="font-weight-bold">Teléfono Interno</span>
              </th>
              <td class="pb-50">
                {{ usuario.telefonoInterno }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-50"
                />
                <span class="font-weight-bold">Teléfono</span>
              </th>
              <td class="pb-50">
                {{ usuario.telefono }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-50"
                />
                <span class="font-weight-bold">Rol</span>
              </th>
              <td class="pb-50">
                {{ usuario.tipoUsuario }}
              </td>
            </tr>

          </table>
        </b-col>

      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <!-- <permisosForm
      v-if="permisos"
      :permisosUsuario.sync="usuario.permissions"
      :permissionsUsuario.sync="usuario.permissionsUsuario"
    /> -->

    <!-- Action Buttons -->
    <!-- <btnSubmit
      :btnText="usuarioSubmit"
    /> -->
  </b-form>
</template>

<script>
import {
  BMedia, BAvatar, BRow, BCol, BFormGroup, BForm,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { validationMixin } from 'vuelidate'
import {
  required, minLength, maxLength, sameAs,
} from 'vuelidate/lib/validators'
import { formatearTelefono } from '@/mixins/forms'

export default {
  components: {
    BMedia,
    // BButton,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BForm,
  },
  mixins: [validationMixin, formatearTelefono],
  props: {
    usuario: {
      type: Object,
      required: true,
    },
    usuarioSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Usuario',
    },
    validarPassword: {
      type: Boolean,
      default: true, // Texto del boton
    },
    configuracion: {
      type: Boolean,
      default: false, // Texto del boton
    },
  },
  validations() {
    return {
      usuario: {
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(30),
        },
        confirmarPassword: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(30),
          sameAsPassword: sameAs('password'),
        },
      },
    }
  },
  setup() {
    const rolOptions = [
      { value: null, text: 'Selecciona un rol' },
      { value: 'Admin', text: 'Admin' },
      { value: 'Bodeguero', text: 'Bodeguero' },
      { value: 'Vendedor', text: 'Vendedor' },
      { value: 'Ingeniero', text: 'Ingeniero' },
    ]

    const estadoOptions = [
      { value: null, text: 'Selecciona un estado' },
      { value: 'Activo', text: 'Activo' },
      { value: 'Inactivo', text: 'Inactivo' },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      this.usuario.imagen = base64
    })

    return {
      previewEl,
      avatarText,
      inputImageRenderer,
      rolOptions,
      estadoOptions,
    }
  },
  methods: {
    onFileSelected(e) {
      // Obtiene primera Imagen
      const file = e.target.files[0]
      this.cargarImg(file)
    },
    cargarImg(file) {
      // Objeto que permite trabajar con archivos
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.usuario.imagen = e.target.result
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
